import React from "react"
import classes from "./CareerPage.module.scss"
import CareerCard from "../CareerCard /CareerCard"
import Image from "../image"
import OpenFormButton from "../layout/OpenFormButton/OpenFormButton"
import { graphql } from "gatsby"
import SEO from "../seo"
import BasicLayout from "../layout/BasicLayout/BasicLayout"
import { Link } from "gatsby"

const CareerPage = ({ data }) => {
  const vacancies = data.allVacanciesJson.edges
  const publishedVacacies = vacancies.filter(
    vacancy => vacancy.node.isPublished,
  )
  const ChangeAllSpacesToDashesInPath = vacancy => {
    const find = " "
    const re = new RegExp(find, "g")
    return vacancy.replace(re, "-")
  }
  return (
    <BasicLayout stickyBtn={false}>
      <SEO title="Career" />
      <div className="container">
        <h1 className={classes.header}> Career </h1>
        {publishedVacacies.length > 0 && (
          <div className={classes.cardWrapper}>
            {publishedVacacies.map(vacancy => {
              return (
                <Link
                  to={`/vacancy/${ChangeAllSpacesToDashesInPath(
                    vacancy.node.name,
                  )}`}
                  key={vacancy.node.id}
                  className={classes.link}
                >
                  <CareerCard
                    technologies={vacancy.node.tags}
                    title={vacancy.node.name}
                    workPlaces={vacancy.node.workPlaces}
                    key={(vacancy.node.id + 1) * 100}
                  />
                </Link>
              )
            })}
          </div>
        )}
        <div className={classes.vacancyNotFound}>
          <div className={classes.pictureHolder}>
            <Image src="think.png" className={classes.picture} />
          </div>
          <div>
            <p className={classes.vacancyNotFoundDescription}>
              Think that you would fit in at Drum’n’Code but haven’t found the
              right open position? We’re always on the lookout for new talent.
            </p>
            <OpenFormButton title="Send your CV" isOpenCV />
          </div>
        </div>
      </div>
    </BasicLayout>
  )
}

export const query2 = graphql`
  query {
    allVacanciesJson {
      edges {
        node {
          id
          isPublished
          workPlaces {
            id
            place
          }
          name
          tags {
            id
            name
          }
        }
      }
    }
  }
`
export default CareerPage
